import React from "react"
// import { graphql } from "gatsby"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import { removePlaceholder } from "../components/helperFunctions"

// Sorts the sponsor logo object array by weight (ascending order)
// reference: https://www.w3schools.com/js/js_array_sort.asp

const ExecutivePage = ({ data }) => {
  let execArray
  // check if executive data exists
  //need a placeholder??
  if (data.executive) {
    execArray = data.executive.edges
  } else {
    execArray = null
  }
  // console.log(execArray[0])

  const sortArraybyWeight = myArray => {
    myArray.sort((a, b) => a.node.weight - b.node.weight)
    // console.log("sponsorArray:", sponsorArray)
    return myArray
  }
  // remove placeholder items from Contentful
  const arrayFiltered = removePlaceholder(execArray)
  // sort array by assigned weights
  const newArray = sortArraybyWeight(arrayFiltered)

  return (
    <div className="pageContent">
      <SEO
        title="Executive"
        keywords={[`Sioux Lookout`, `hockey`, `executive`, `info`]}
      />
      <h1 className="base__pageTitle">Executive Members</h1>

      <table className="exec__table">
        <thead></thead>
        <tbody>
          <tr className="exec__tableTitles">
            <td>Executive Position</td>
            <td>Name</td>
            <td className="exec__email">Email</td>
          </tr>
          {newArray
            ? newArray.map(exec => (
                <tr className="exec__dataRow" key={`${exec.node.id}`}>
                  <td>{`${exec.node.executivePosition}`}</td>
                  <td className="exec__name">
                    <a
                      href={`mailto:${exec.node.email}?subject=Minor%20Hockey`}
                    >{`${exec.node.fullName}`}</a>
                  </td>
                  <td className="exec__email">
                    <a
                      href={`mailto:${exec.node.email}?subject=Minor%20Hockey`}
                    >{`${exec.node.email}`}</a>
                  </td>
                </tr>
              ))
            : null}

          {/* (<tr><td>{`${exec.node.fullName}}`</td></tr>) */}
        </tbody>
      </table>
    </div>
  )
}

export default ExecutivePage

export const pageQuery = graphql`
  query execQuery {
    executive: allContentfulExecutiveMember {
      edges {
        node {
          id
          fullName
          executivePosition
          email
          weight
          placeholder
        }
      }
    }
  }
`
